<template>
  <div class="loader">
    <div class="textlogo">
      <span class="path path_1 inline-block">W</span>
      <span class="path path_2 inline-block">I</span>
      <span class="path path_3 inline-block">R</span>
      <span class="path path_4 inline-block">D</span>
      <span class="path  inline-block">&nbsp;</span>
      <span class="path path_5 inline-block">G</span>
      <span class="path path_6 inline-block">E</span>
      <span class="path path_7 inline-block">L</span>
      <span class="path path_8 inline-block">A</span>
      <span class="path path_9 inline-block">D</span>
      <span class="path path_10 inline-block">E</span>
      <span class="path path_11 inline-block">N</span>
    </div>
  </div>
</template>
